export const SHAREPOINT_SITE_URL = 'https://legogroup.sharepoint.com/sites/VisitorManagement';

export const SHAREPOINT_VISITOR_INFORMATION_PACKAGE_URL = `${SHAREPOINT_SITE_URL}/SitePages/Visitor-Information-Package.aspx`;

export const SHAREPOINT_FAQ_URL = `${SHAREPOINT_SITE_URL}/SitePages/Frequently-Asked-Questions.aspx`;

export class ErrorWithMessage extends Error {
  public constructor(message: string) {
    super(message);

    this.name = 'ErrorWithMessage';
  }
}

export const isErrorWithMessage = (obj: unknown): obj is ErrorWithMessage => {
  if (typeof obj !== 'object' || !obj) {
    return false;
  }

  return 'name' in obj && (obj as Error).name === 'ErrorWithMessage';
};
