import { extendTheme, Theme } from '@lego/klik-ui';

const extend = extendTheme as (dict: Partial<Theme> | Record<string, any>) => Theme;

export const theme = extend({
  components: {
    Menu: {
      baseStyle: {
        item: {
          paddingLeft: 3, // edge 16 does not understand padding-start
          paddingRight: 3,
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          paddingLeft: 3, // edge 16 does not understand padding-start
          paddingRight: 3,
        },
      },
    },
    FormError: {
      baseStyle: {
        text: {
          marginLeft: '0.5rem', // edge 16 does not understand margin-start
        },
      },
    },
    Checkbox: {
      baseStyle: {
        label: {
          marginLeft: '0.5rem', // edge 16 does not understand margin-start
        },
      },
    },
  },
});
