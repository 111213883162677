import { captureException } from '@sentry/react';
import { loadCustomProperties, saveCustomProperties } from './office-api';

const CUSTOM_PROP_ATTACHED_DETAILS = 'LEGO_VMS_DETAILS';

export type AttachedDetails = {
  locationId: string;
  attendees: string[];
  accessEndDate?: string;
};

export const stringifyDetails = (
  details: AttachedDetails | undefined
): string | undefined => {
  if (!details) {
    return undefined;
  }

  return JSON.stringify(details);
};

export const parseDetails = (
  detailsString: string | undefined
): AttachedDetails | undefined => {
  if (!detailsString) {
    return undefined;
  }

  try {
    return JSON.parse(detailsString) as AttachedDetails;
  } catch (e) {
    console.error('failed to parse attached details');
    captureException(e);

    return undefined;
  }
};

export const storeAttachedVisitDetails = async (
  details: string | undefined
): Promise<void> => {
  const customProps = await loadCustomProperties();

  if (details) {
    customProps.set(CUSTOM_PROP_ATTACHED_DETAILS, details);
  } else {
    customProps.remove(CUSTOM_PROP_ATTACHED_DETAILS);
  }

  await saveCustomProperties(customProps);
};

export const loadAttachedVisitDetails = async (): Promise<
  AttachedDetails | undefined
> => {
  try {
    const customProps = await loadCustomProperties();
    const stringified = customProps.get(CUSTOM_PROP_ATTACHED_DETAILS) as
      | string
      | undefined;

    return parseDetails(stringified);
  } catch (e: unknown) {
    console.log('Failed to load custom props', e);

    return undefined;
  }
};
