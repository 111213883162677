/* eslint-disable no-console */
/* eslint-disable max-lines-per-function */
/* eslint-disable react/display-name */
import { Box, Popover, Text, useToast } from '@lego/klik-ui';
import { CheckTickBold } from '@lego/klik-ui-icons';
import { captureException } from '@sentry/react';
import { isApiErrorResponse, outlookAddinPost } from '@vms/common';
import { differenceInDays } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  aggregatedLocationState,
  appointmentDatesState,
  attachedDetailsState,
  externalAttendeesState,
  hasRecurrencePattern,
  hostEmailState,
  isEmbeddingState,
  vmsRecipientState,
} from '../utils/atoms';
import {
  AnimatedButton,
  AnimatedPopoverContent,
  buttonVariants,
  checkmarkVariants,
  textVariants,
} from '../utils/attach-button-animation-vars';
import { ErrorWithMessage, isErrorWithMessage } from '../utils/constants';
import { assembleClientInfo, getSubject } from '../utils/helpers';
import {
  embedIdInBody,
  extractIdFromBody,
  getItemId,
  updateVMSAttendee,
} from '../utils/office-api';
import { stringifyDetails } from '../utils/storage';
import { Toast } from './Toast';

const DELAY = 5;
const HIDE_DELAY = 4;

// eslint-disable-next-line max-lines-per-function, complexity
export const AttachButton: FC = () => {
  const externals = useRecoilValue(externalAttendeesState);
  const hasExternals = externals.length > 0;

  const [attachedDetails, setAttachedDetails] =
    useRecoilState(attachedDetailsState);

  const locationId = useRecoilValue(aggregatedLocationState);
  const hostEmail = useRecoilValue(hostEmailState);
  const [isEmbedding, setIsEmbedding] = useRecoilState(isEmbeddingState);
  const toast = useToast();
  const setVMSRecipient = useSetRecoilState(vmsRecipientState);
  const [isAnimatingCheckIcon, setIsAnimatingCheckIcon] = useState(false);
  const [isReminding, setIsReminding] = useState(false);
  const [isPopoverAnimDisabled, setIsPopoverAnimDisabled] = useState(false);
  const dates = useRecoilValue(appointmentDatesState);
  const hasPattern = useRecoilValue(hasRecurrencePattern);

  // show a popover after 5 seconds of selecting a location for 4 seconds to remind user to click button
  useEffect(() => {
    if (hasExternals) {
      setTimeout(() => {
        setIsReminding(true);

        setTimeout(() => {
          setIsReminding(false);
        }, HIDE_DELAY * 1000);
      }, DELAY * 1000);
    }
  }, [hasExternals]);

  // disable animation if details already attached or previous clicked the attach button
  useEffect(() => {
    if (attachedDetails || localStorage.getItem('disablePopoverAnimation')) {
      setIsPopoverAnimDisabled(true);
    }
  }, [attachedDetails]);

  const canQueryApi = !!(hostEmail && locationId);

  // Build a string representation of the data we attach when clicking the button
  const detailsToAttach = useMemo(() => {
    if (!locationId) {
      return undefined;
    }

    return stringifyDetails({
      locationId,
      attendees: externals.map((e) => e.emailAddress),
      accessEndDate: dates.end.toISOString(),
    });
  }, [locationId, externals, dates.end]);

  const isButtonEnabled =
    canQueryApi &&
    detailsToAttach !== attachedDetails &&
    hasExternals &&
    !isEmbedding &&
    differenceInDays(dates.end, dates.start) <= 30 &&
    !hasPattern;

  const onClick = useCallback(() => {
    // eslint-disable-next-line max-statements, complexity
    (async () => {
      if (!canQueryApi) {
        return;
      }

      setIsEmbedding(true);

      try {
        console.log('Trying to save item into guest exp api...');

        const loggedInUserEmail =
          Office.context.mailbox.userProfile.emailAddress || hostEmail;

        if (!loggedInUserEmail) {
          throw new Error('No sender email?');
        }

        if (!locationId) {
          throw new Error('No location?');
        }

        console.log(externals);

        const visitors = externals.map((a) => ({
          // Outlook sets display name as email if only e-mail is known but we don't want to use e-mail as name in invite
          name: a.displayName === a.emailAddress ? '' : a.displayName,
          email: a.emailAddress,
        }));

        const title = await getSubject();

        try {
          const clientInfo = assembleClientInfo();

          const outlookEventId = await extractIdFromBody();

          const itemId = await getItemId();

          console.log('outlookEventId', outlookEventId);

          const res = await outlookAddinPost({
            outlookEventId,
            itemId,
            hostEmail: loggedInUserEmail, // Actual signed user
            onBehalfOfEmail:
              loggedInUserEmail !== hostEmail ? hostEmail : undefined, // Email of people it is sent on-behalf of (same as above for )
            locationId,
            visitors,
            title,
            accessEndDate: dates.end.toISOString(),
            clientInfo,
            visitorType: 'BusinessVisitor',
            //TODO visitor type (get it from api and let user select)
            //TODO observers (for PA/EA if someone else is picking up host to notify them when they check-in)
          });

          await embedIdInBody(res.data.outlookEventId);

          setVMSRecipient(res.data.mailbox);
          await updateVMSAttendee(res.data.mailbox);

          // Store what we have attached so we can compare later
          setAttachedDetails(detailsToAttach);

          // Show success toast to user
          if (res.data.doesBehalfOfEmailHaveEmployeeId === false) {
            toast({
              duration: 10000,
              position: 'top-right',
              render: () => (
                <Toast title='Details added' variant='info'>
                  You are sending a event invite using a shared email or a user
                  without an employee id, you will become the host of this
                  event.
                </Toast>
              ),
            });
          } else {
            toast({
              position: 'top-right',
              render: () => (
                <Toast title='Details added' variant='success'>
                  You may close this panel
                </Toast>
              ),
            });
          }

          // trigger animation
          setIsAnimatingCheckIcon(true);
          setTimeout(() => setIsAnimatingCheckIcon(false), 4000);

          // disable popover animation for next time
          localStorage.setItem('disablePopoverAnimation', 'true');

          setIsEmbedding(false);
        } catch (e) {
          if (isApiErrorResponse(e)) {
            const error = e.data;

            throw new ErrorWithMessage(error.message);
          }

          throw new Error(
            'We got errors from querying API... Which was unexpected... Because of the errorPolicy...'
          );
        }
      } catch (err) {
        const message = isErrorWithMessage(err)
          ? err.message
          : 'Failed to attach meeting details to meeting';

        toast({
          position: 'top-right',
          render: () => (
            <Toast title='Details added' variant='error'>
              {message}
            </Toast>
          ),
        });

        console.error(err);
        captureException(err);
        setIsEmbedding(false);
      }
    })();
  }, [
    canQueryApi,
    setIsEmbedding,
    hostEmail,
    locationId,
    externals,
    setVMSRecipient,
    setAttachedDetails,
    detailsToAttach,
    toast,
    dates.end,
  ]);

  const buttonLabel = useMemo(
    () => (attachedDetails ? 'Update visit details' : 'Save visit details'),
    [attachedDetails]
  );

  const handleOnMouseEnter = useCallback(
    () => setIsPopoverAnimDisabled(true),
    [setIsPopoverAnimDisabled]
  );

  return (
    <Box>
      {hasPattern ? (
        <Text color='error.400' fontSize='xs' textAlign='center'>
          Repeating events not supported
        </Text>
      ) : null}
      <Popover isOpen={isReminding} size='lg'>
        <Popover.Trigger>
          <Box w='100%'>
            <AnimatedButton
              animate={isAnimatingCheckIcon ? 'animate' : 'initial'}
              isDisabled={!isButtonEnabled}
              isLoading={isEmbedding}
              onClick={onClick}
              variants={buttonVariants}
              width='full'
            >
              <AnimatePresence mode='wait'>
                {isAnimatingCheckIcon && (
                  <motion.div
                    animate='animate'
                    exit='exit'
                    initial='initial'
                    variants={checkmarkVariants}
                  >
                    <CheckTickBold />
                  </motion.div>
                )}

                {!isAnimatingCheckIcon && (
                  <motion.span
                    animate='animate'
                    initial='initial'
                    variants={textVariants}
                  >
                    {buttonLabel}
                  </motion.span>
                )}
              </AnimatePresence>
            </AnimatedButton>
          </Box>
        </Popover.Trigger>
        {!isPopoverAnimDisabled && (
          <AnimatedPopoverContent
            animate={{
              y: [0, -30, 5, 0],
              transition: { delay: DELAY + 0.5, repeat: 3 },
            }}
            justifyContent='center'
            m='0 auto'
            maxW={230}
            onMouseEnter={handleOnMouseEnter}
          >
            <Popover.Arrow />
            <Popover.Body>Remember to save details</Popover.Body>
          </AnimatedPopoverContent>
        )}
      </Popover>
    </Box>
  );
};
