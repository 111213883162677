import { Text } from '@lego/klik-ui';
import React, { ReactNode } from 'react';

export const Headline: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Text fontSize="lg" mb={5}>
      {children}
    </Text>
  );
};
