import { css } from '@emotion/react';
import { InlineNotification } from '@lego/klik-ui';
import React, { FC } from 'react';

export type ToastProps = {
  variant: 'error' | 'info' | 'success';
  title?: string;
  children: React.ReactNode;
};

export const Toast: FC<ToastProps> = ({ variant, title, children }) => {
  return (
    <InlineNotification
      css={css`
        & > * {
          border-left-width: 0.75rem; // The wrapper uses "border-inline-start-width" which is not supported in edge 16
        }
      `}
      variant={variant}
    >
      <InlineNotification.Content alignItems="flex-start" flexDirection="column" pl={3}>
        {title && <InlineNotification.Title>{title}</InlineNotification.Title>}
        <InlineNotification.Description>{children}</InlineNotification.Description>
      </InlineNotification.Content>
    </InlineNotification>
  );
};
