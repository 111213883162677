import { Box, Divider, Flex, InlineNotification, Spacer } from '@lego/klik-ui';
import { FC } from 'react';
// eslint-disable-next-line import/no-unassigned-import
import 'react-datepicker/dist/react-datepicker.css';
import { useRecoilValue } from 'recoil';
import { AppContent } from './components/AppContent';
import { AttachButton } from './components/AttachButton';
import { Header } from './components/Header';
import { hasRecurrencePattern } from './utils/atoms';
import { isDevMode } from './utils/helpers';
import { useOutlookData } from './utils/hooks';

export const App: FC = () => {
  // Synchronizes our app state with the contents of the outlook meeting window
  useOutlookData();
  const hasPattern = useRecoilValue(hasRecurrencePattern);

  return (
    <Flex bgColor="slate.50" direction="column" height="100vh">
      <Header />
      <Box flex={1} overflow="auto">
        {isDevMode && (
          <InlineNotification variant="warning">
            <InlineNotification.Content>
              <InlineNotification.Title>DEVELOPMENT MODE</InlineNotification.Title>
            </InlineNotification.Content>
          </InlineNotification>
        )}
        <Box p={4}>
          <AppContent />
        </Box>
        <Spacer />
      </Box>
      <Divider />
      <Box
        background="slate.50"
        borderTop="solid 1px rgba(47, 47, 46, 0.3)"
        pb={4}
        pt={hasPattern ? 0 : 4}
        px={4}
      >
        <AttachButton />
      </Box>
    </Flex>
  );
};
