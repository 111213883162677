// eslint-disable-next-line node/no-extraneous-import,import/no-extraneous-dependencies
import { Box, Button, Dropdown, Flex, Popover, Portal, Text } from '@lego/klik-ui';
import { FC, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  aggregatedLocationState,
  isEmbeddingState,
  locationsState,
  selectedLocationState,
} from '../utils/atoms';

export const LocationSelector: FC = () => {
  const setSelectedLocation = useSetRecoilState(selectedLocationState);
  const isEmbedding = useRecoilValue(isEmbeddingState);
  const locationSelectValue = useRecoilValue(aggregatedLocationState);
  const locations = useRecoilValue(locationsState);

  const handleChange = useCallback(
    (value: string) => {
      // Klik dropdown uses empty string as no selection :facepalm:
      setSelectedLocation(value || undefined);
    },
    [setSelectedLocation]
  );

  const options = useMemo(() => {
    return (locations ?? []).map((location) => (
      <Dropdown.Option key={location.id} value={location.id}>
        {location.name}
      </Dropdown.Option>
    ));
  }, [locations]);

  return (
    <Box mt={4}>
      <Text color="slate.900">Check-in reception</Text>
      <Box my={1}>
        <Dropdown
          isDisabled={isEmbedding}
          onChange={handleChange}
          value={locationSelectValue ? [locationSelectValue] : []}
        >
          {options}
        </Dropdown>
        <Popover>
          <Popover.Trigger>
            <Flex justifyContent="flex-end" mt={1}>
              <Button size="xs" variant="ghost">
                Why is my location not here?
              </Button>
            </Flex>
          </Popover.Trigger>
          <Portal>
            <Popover.Content ml={1} mr={1}>
              <Popover.Arrow />
              <Popover.CloseButton aria-label="close popover" />
              <Popover.Header>Visitor Management System is underway!</Popover.Header>
              <Popover.Body>
                <Text>
                  Please note that the Visitor management System is not yet enabled for all
                  locations in The LEGO Group. If the location you wish to invite visitors to is not
                  listed, kindly contact your local reception or other personnel for guidance on
                  inviting visitors.
                </Text>
              </Popover.Body>
            </Popover.Content>
          </Portal>
        </Popover>
      </Box>
    </Box>
  );
};
