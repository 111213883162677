import {
  IconButton,
  InlineNotification,
  Link,
  Navbar,
  Popover,
  Portal,
  Spacer,
  Text,
  useDisclosure,
} from '@lego/klik-ui';
import { StatusHelpBold } from '@lego/klik-ui-icons';
import { FC, useEffect, useState } from 'react';
import { SHAREPOINT_FAQ_URL, SHAREPOINT_SITE_URL } from '../utils/constants';
import { getItemId } from '../utils/office-api';

const onClickSharepoint = () => window.open(SHAREPOINT_SITE_URL);
const onClickFaq = () => window.open(SHAREPOINT_FAQ_URL);

export const Header: FC = () => {
  const { onOpen, isOpen } = useDisclosure();
  const [itemId, setItemId] = useState<string | undefined>();

  useEffect(() => {
    getItemId().then(setItemId);
  }, [setItemId]);

  return (
    <Navbar>
      <Navbar.Brand>
        <Text size="large">Visit details</Text>
      </Navbar.Brand>
      <Spacer />
      <Navbar.Segment>
        <Popover>
          <Popover.Trigger>
            <IconButton
              aria-label="help"
              colorScheme="whiteAlpha"
              icon={<StatusHelpBold />}
              variant="ghost"
            />
          </Popover.Trigger>
          <Portal>
            <Popover.Content ml={1} mr={1}>
              <Popover.Arrow />
              <Popover.CloseButton aria-label="close popover" />
              <Popover.Header>What is this?</Popover.Header>
              <Popover.Body>
                <Text>
                  This outlook add-in is used to invite external business visitors to LEGO®
                  premises. It will send out a nice email to your guests with a QR code they can use
                  for easy self-service checkin on arrival.
                </Text>
                <Text mt={2}>
                  Currently we only support Campus in Billund but we are working on maturing the
                  solution so we can roll it out to other offices. Learn more on our{' '}
                  <Link isInline={true} onClick={onClickSharepoint}>
                    sharepoint site
                  </Link>
                  .
                </Text>
                <Text mt={2}>
                  If you encounter problems using the addin please check our{' '}
                  <Link isInline={true} onClick={onClickFaq}>
                    FAQ page
                  </Link>{' '}
                  for possible solutions.
                </Text>

                {isOpen ? (
                  <InlineNotification mt={2}>
                    <InlineNotification.Content>
                      <Text>Useragent: {navigator.userAgent}</Text>
                      <Text>
                        Host: {Office.context.mailbox.diagnostics.hostName}{' '}
                        {Office.context.mailbox.diagnostics.hostVersion}
                      </Text>
                      <Text>
                        Support changeset 1.8:{' '}
                        {Office.context.requirements.isSetSupported('Mailbox', '1.8')
                          ? 'Yes'
                          : 'No'}
                      </Text>
                      <Text>GIT Sha: {import.meta.env.VITE_GIT_DESCRIBE ?? '-'}</Text>
                      <Text style={{ lineBreak: 'anywhere' }}>Item Id: {itemId ?? '-'}</Text>
                    </InlineNotification.Content>
                  </InlineNotification>
                ) : (
                  <Link color="slate.200" mt={3} onClick={onOpen} size="sm">
                    Show debug
                  </Link>
                )}
              </Popover.Body>
            </Popover.Content>
          </Portal>
        </Popover>
      </Navbar.Segment>
    </Navbar>
  );
};
