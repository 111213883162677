import { Accordion, Text } from '@lego/klik-ui';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import {
  aggregatedLocationState,
  externalAttendeesState,
  locationsState,
  selectedLocationState,
  suggestedLocationState,
} from '../utils/atoms';
import { AccessCardDuration } from './AccessCardDuration';
import { Headline } from './Headline';
import { LocationSelector } from './LocationSelector';

export const HasExternalsContent: FC = () => {
  const suggestedLocation = useRecoilValue(suggestedLocationState);
  const selectedLocation = useRecoilValue(selectedLocationState);
  const aggregatedLocation = useRecoilValue(aggregatedLocationState);
  const externals = useRecoilValue(externalAttendeesState);
  const locations = useRecoilValue(locationsState);

  const location = locations?.find((l) => l.id === aggregatedLocation);

  const shouldShowAccessAccordion =
    location?.printSystem === 'SagioLegacyCampusVMS' ||
    location?.accessControlSystem === 'GenetecImportAPI';

  return (
    <section>
      <Headline>
        Visit details for {externals.length} external{' '}
        {externals.length === 1 ? 'visitor' : 'visitors'}
      </Headline>
      <LocationSelector />
      {suggestedLocation && !selectedLocation && (
        <Text color='slate.600' fontSize='sm'>
          Based on Outlook meeting location
        </Text>
      )}
      {shouldShowAccessAccordion && (
        <Accordion allowToggle={true} mt={4}>
          <AccessCardDuration />
        </Accordion>
      )}
    </section>
  );
};
