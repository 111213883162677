import { Button, IButtonProps, Popover } from '@lego/klik-ui';
import { CustomDomComponent, motion } from 'framer-motion';

// custom component wrapper from framer motion
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore weird error in some versions of ts where "union type is too complex"
export const AnimatedButton: CustomDomComponent<IButtonProps> = motion(Button);

export const AnimatedPopoverContent: CustomDomComponent<any> = motion(Popover.Content);

const animationDuration = 2; // seconds

export const checkmarkVariants = {
  initial: {
    opacity: 0,
    rotate: 0,
  },
  animate: {
    opacity: 1,
    rotate: 360,
    transition: {
      duration: animationDuration,
    },
  },
  exit: {
    opacity: 0,
    y: -5,
    transition: {
      duration: animationDuration,
    },
  },
};

export const textVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

export const buttonVariants = {
  animate: {
    backgroundColor: 'var(--colors-success-400)',
    transition: {
      duration: animationDuration,
      ease: 'easeInOut',
    },
  },
};
