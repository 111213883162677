import { components } from '@vms/common';
import { addSeconds } from 'date-fns';

// E-Mail addresses compliant to RFC 822
const emailRegEx =
  // eslint-disable-next-line prefer-named-capture-group, require-unicode-regexp
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const isValidEmail = (emailAddr: string): boolean => emailRegEx.test(emailAddr);

// list of lego employee domains
const LEGO_EMPLOYEE_DOMAIN_LIST = ['lego.com', 'kirkbi.com', 'legohouse.com'];

export const isEmailExternal = (emailAddr: string): boolean => {
  const isNonLegoEmail = LEGO_EMPLOYEE_DOMAIN_LIST.every(
    (domain) => !emailAddr.toLowerCase().endsWith(domain)
  );

  return isNonLegoEmail && isValidEmail(emailAddr);
};

// Tries to extracts a human readable company name from an email e.g.:
//   'john@consultant.lego.com' => 'Lego'
export const extractCompany = (email: string): string | undefined => {
  const blackList = ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'msn.com', 'live.com'];

  let [, domain] = email.split('@');

  if (!domain) {
    return undefined;
  }

  if (domain.endsWith('co.uk')) {
    domain = domain.slice(0, domain.lastIndexOf('.'));
  }

  if (blackList.includes(domain)) {
    return undefined;
  }

  const domainName = domain.split('.').slice(-2, -1)[0];

  if (!domainName) {
    return undefined;
  }

  return domainName[0].toUpperCase() + domainName.slice(1);
};

/**
 * Finds the length of the longest common substring.
 * Implementation of the pseudocode from https://en.wikipedia.org/wiki/Longest_common_substring_problem
 * @param s1 First string
 * @param s2 Second string
 */
export const longestCommonSubstring = (s1: string, s2: string): number => {
  if (s1 === '' || s2 === '') {
    return 0;
  }

  s1 = s1.toLocaleLowerCase();
  s2 = s2.toLocaleLowerCase();
  let z = 0;

  const c: number[][] = new Array(s1.length + 1)
    .fill(undefined)
    .map(() => new Array(s2.length + 1).fill(0) as number[]);

  for (let i = 1; i <= s1.length; i++) {
    for (let j = 1; j <= s2.length; j++) {
      if (s1[i - 1] === s2[j - 1]) {
        c[i][j] = c[i - 1][j - 1] + 1;
        z = Math.max(c[i][j], z);
      } else {
        c[i][j] = 0;
      }
    }
  }

  return z;
};

export const getQueryVariable = (variable: string): string | undefined => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i++) {
    const [key, value] = vars[i].split('=');

    if (key === variable) {
      return value;
    }
  }

  return undefined;
};

export const assembleClientInfo = (): components['schemas']['OutlookClientInfo'] => {
  return {
    platform: Office.context.platform.toString(),
    version: Office.context.diagnostics.version,
    host: Office.context.diagnostics.host.toString(),
  };
};

export const getSubject = (): Promise<string | undefined> => {
  return new Promise((resolve) => {
    Office.context.mailbox.item?.subject.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        resolve('Meeting at the LEGO Group');
      } else {
        resolve(result.value);
      }
    });
  });
};

export const isAllDayMeeting = (start: Date, end: Date): boolean => {
  if (Office.context.platform === Office.PlatformType.Mac) {
    // On outlook mac all day meetings are returned as midnight to midnight UTC (this should be considered a bug in office.js)
    return start !== end && start.getUTCHours() === 0 && end.getUTCHours() === 0;
  }

  // On other platforms all day meetings are returned as midnight to midnight local time (as it should)
  return start !== end && start.getHours() === 0 && end.getHours() === 0;
};

export const correctAllDayMeeting = (start: Date, end: Date): { start: Date; end: Date } => {
  if (Office.context.platform === Office.PlatformType.Mac) {
    // On outlook mac all day meetings are returned as midnight to midnight UTC
    // So to get the actual local all day meeting we need to strip away the timezone
    // and consider the date local time
    const localStartDate = new Date(`${start.toISOString().slice(0, 10)}T00:00`);

    const localEndDate = new Date(`${end.toISOString().slice(0, 10)}T00:00`);

    return {
      start: localStartDate,
      end: addSeconds(localEndDate, -1),
    };
  }

  // In other cases all day meetings are returned as midnight to midnight local time.
  // So here we only need to rewind the end time one second so we don't span 2 days.
  return {
    start,
    end: addSeconds(end, -1),
  };
};

export const isDevMode = localStorage.getItem('devMode')?.toLowerCase() === 'true';
